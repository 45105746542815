<template>
  <a-modal v-model="visible" width="70%" :afterClose="close" :footer="null">
    <table
      class="wrapper"
      width="100%"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
      style="
        box-sizing: border-box;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
          'Segoe UI Symbol';
        position: relative;
        -premailer-cellpadding: 0;
        -premailer-cellspacing: 0;
        -premailer-width: 100%;
        background-color: #edf2f7;
        margin: 0;
        padding: 0;
        width: 100%;
      "
    >
      <tr>
        <td
          align="center"
          style="
            box-sizing: border-box;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';
            position: relative;
          "
        >
          <table
            class="content"
            width="100%"
            cellpadding="0"
            cellspacing="0"
            role="presentation"
            style="
              box-sizing: border-box;
              font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
                Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                'Segoe UI Emoji', 'Segoe UI Symbol';
              position: relative;
              -premailer-cellpadding: 0;
              -premailer-cellspacing: 0;
              -premailer-width: 100%;
              margin: 0;
              padding: 0;
              width: 100%;
            "
          >
            <div
              class="header"
              style="
                box-sizing: border-box;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                  'Segoe UI Emoji', 'Segoe UI Symbol';
                position: relative;
                padding: 25px 0;
                text-align: center;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 570px;
                margin: auto;
              "
            >
              <img
                :src="`${
                  mailData.ru.mail.logo
                    ? mailData.ru.mail.logo
                    : 'https://carsell-back.utest.space/storage/settings/logo.png'
                }`"
                alt="Logo"
                style="
                  box-sizing: border-box;
                  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                    'Segoe UI Emoji', 'Segoe UI Symbol';
                  position: relative;
                  max-width: 100%;
                "
              />
              <a
                v-for="item in mailData.ru.mail.links"
                :key="item.index"
                :href="item.url"
                style="
                  box-sizing: border-box;
                  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                    'Segoe UI Emoji', 'Segoe UI Symbol';
                  color: #3d4852;
                  font-size: 19px;
                  font-weight: bold;
                  text-decoration: none;
                "
                target="_blank"
                >{{ item.title }}</a
              >
            </div>

            <!-- Email Body -->
            <tr>
              <td
                class="body"
                width="100%"
                cellpadding="0"
                cellspacing="0"
                style="
                  box-sizing: border-box;
                  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                    'Segoe UI Emoji', 'Segoe UI Symbol';
                  position: relative;
                  -premailer-cellpadding: 0;
                  -premailer-cellspacing: 0;
                  -premailer-width: 100%;
                  background-color: #edf2f7;
                  border-bottom: 1px solid #edf2f7;
                  border-top: 1px solid #edf2f7;
                  margin: 0;
                  padding: 0;
                  width: 100%;
                "
              >
                <table
                  class="inner-body"
                  align="center"
                  width="570"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="
                    box-sizing: border-box;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                      Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                      'Segoe UI Emoji', 'Segoe UI Symbol';
                    position: relative;
                    -premailer-cellpadding: 0;
                    -premailer-cellspacing: 0;
                    -premailer-width: 570px;
                    background-color: #ffffff;
                    border-color: #e8e5ef;
                    border-radius: 2px;
                    border-width: 1px;
                    box-shadow: 0 2px 0 rgba(0, 0, 150, 0.025),
                      2px 4px 0 rgba(0, 0, 150, 0.015);
                    margin: 0 auto;
                    padding: 0;
                    width: 570px;
                  "
                >
                  <!-- Body content -->
                  <tr>
                    <td
                      class="content-cell"
                      style="
                        box-sizing: border-box;
                        font-family: -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
                          'Apple Color Emoji', 'Segoe UI Emoji',
                          'Segoe UI Symbol';
                        position: relative;
                        max-width: 100vw;
                        padding: 32px;
                      "
                    >
                      <h3
                        style="
                          box-sizing: border-box;
                          font-family: -apple-system, BlinkMacSystemFont,
                            'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
                            'Apple Color Emoji', 'Segoe UI Emoji',
                            'Segoe UI Symbol';
                          position: relative;
                          font-size: 14px;
                          font-weight: bold;
                          margin-top: 0;
                          text-align: left;
                        "
                      >
                        {{ mailData.ru.title || mailData.ua.title }}
                      </h3>
                      <p
                        style="
                          box-sizing: border-box;
                          font-family: -apple-system, BlinkMacSystemFont,
                            'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
                            'Apple Color Emoji', 'Segoe UI Emoji',
                            'Segoe UI Symbol';
                          position: relative;
                          font-size: 16px;
                          line-height: 1.5em;
                          margin-top: 0;
                          text-align: left;
                        "
                      >
                        {{ mailData.ru.name || mailData.ua.name }}
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style="
                  box-sizing: border-box;
                  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                    'Segoe UI Emoji', 'Segoe UI Symbol';
                  position: relative;
                "
              >
                <table
                  class="footer"
                  align="center"
                  width="570"
                  cellpadding="0"
                  cellspacing="0"
                  role="presentation"
                  style="
                    box-sizing: border-box;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                      Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
                      'Segoe UI Emoji', 'Segoe UI Symbol';
                    position: relative;
                    -premailer-cellpadding: 0;
                    -premailer-cellspacing: 0;
                    -premailer-width: 570px;
                    margin: 0 auto;
                    padding: 0;
                    text-align: center;
                    width: 570px;
                  "
                >
                  <tr>
                    <td
                      class="content-cell"
                      align="center"
                      style="
                        box-sizing: border-box;
                        font-family: -apple-system, BlinkMacSystemFont,
                          'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
                          'Apple Color Emoji', 'Segoe UI Emoji',
                          'Segoe UI Symbol';
                        position: relative;
                        max-width: 100vw;
                        padding: 32px;
                      "
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </a-modal>
</template>

<script>
export default {
  name: 'ReviewModal',
  data() {
    return {
      visible: false,
      mailData: {
        ru: {
          name: '',
          title: '',
          mail: {
            logo: '',
            links: [
              { title: '', url: '' },
              { title: '', url: '' },
              { title: '', url: '' },
              { title: '', url: '' },
            ],
          },
        },
        ua: {
          name: '',
          title: '',
        },
        all: false,
        status_notice_id: null,
        recipient_id: [],

      },
    }
  },
  methods: {
    open(form) {
      this.visible = true
      this.mailData = Object.assign(form)
    },
    close() {
      this.visible = false
    },
  },
}
</script>

<style scoped lang="scss">
@media only screen and (max-width: 600px) {
  .inner-body {
    width: 100% !important;
  }

  .footer {
    width: 100% !important;
  }
}

@media only screen and (max-width: 500px) {
  .button {
    width: 100% !important;
  }
}
</style>
